var name_recaptcha = 'meta[name="JsRecaptchaResponseTicket"]',
  current_recaptcha_token = '';

$(function() {
  grecaptcha.ready(function () {
    grecaptcha.execute('6Le-ZRoaAAAAACiu9L8eM8RF3tS0gOuCHS4pN_f2',
      { action: 'submit' }).then(function (token) {
        current_recaptcha_token = token;
        document.querySelector(name_recaptcha).content = token;
        var recaptchaResponses = document.getElementsByClassName('recaptchaResponse');
        Array.prototype.forEach.call(recaptchaResponses, function(recaptchaResponse) {
          recaptchaResponse.value = current_recaptcha_token;
        });
    });
  });
});

document.addEventListener("turbolinks:before-visit", function(e) {
  e.preventDefault(),
  window.location = e.data.url
})

$( document ).on('turbolinks:load', function() {
  var recaptcha_element = document.querySelector(name_recaptcha);
  if(recaptcha_element.content == ''){
    recaptcha_element.content = current_recaptcha_token;
  }else{
    current_recaptcha_token = recaptcha_element.content;
  }
  var recaptchaResponses = document.getElementsByClassName('recaptchaResponse');
  Array.prototype.forEach.call(recaptchaResponses, function(recaptchaResponse) {
    recaptchaResponse.value = current_recaptcha_token;
  });

  $('ymaps').each(function(index, map){
    map.remove();
  });

  $('.JsSelectboxCheckbox').each(function(index, selectCheckbox){
    selectBoxCheckbox($(this));
  });

  $('.JsMap').each(function(index, map) {
    var element = $(this);
    var id = element.attr('id');
    if(element.data('complex')){
      var complex = element.data('complex');
      $.get("/housesCoordinatesForComplex", {id: id, complex_id: complex});
    }else{
      var latitude = element.data('lat'),
        longitude = element.data('long');
      showYandexMap(id, latitude, longitude);
    }
  });

  if ($('.JsFormPhone').length != 0) {
    $('.JsFormPhone').each(function(index, phoneElement){
      const element = this;
      const maskOptions = {
        mask: '+{7}(000)000-00-00'
      };
      const mask = IMask(element, maskOptions);
    });
  }

  if($('#JsMainMap').length != 0) {
    $.get("/allCoordinates");
  }

  if($('.JsGalleryTop').length != 0){

    if($('.JsGalleryThumbs').length != 0){
      var galleryThumbs = new Swiper('.JsGalleryThumbs', {
        spaceBetween: 5,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
    }

    var mySwiper = new Swiper ('.JsGalleryTop', {
      // Optional parameters
      // direction: 'vertical',
      loop: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs
      }
    });
  }

  if(typeof VK !== 'undefined') VK.init({apiId: 7489708, onlyWidgets: true});
  if($('#JsVkComment').length != 0) VK.Widgets.Comments('JsVkComment');

  if($('#JsScrolling').length > 0 && $('#JsMessagesShowMore').length > 0){
    window.addEventListener('scroll', () => {
      if($('#JsScrollingLoading').length == 0 && $('#JsMessagesShowMore').length != 0){
        var element = $('#JsScrolling');

        var contentHeight = element.height() + element.offset().top - 500;
        var yOffset       = window.pageYOffset;
        var window_height = window.innerHeight;
        var y             = yOffset + window_height;

        // если пользователь достиг конца
        if(y >= contentHeight){
          element.append("<div id='JsScrollingLoading' class='scrollingloading'><div class='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>");
          $('#JsMessagesShowMore').click();
        }
      }
    });
  }

  if($('#JsStickyBlock').length > 0){
    var block = $('#JsStickyBlock'),
      additionalInfo = $('#JsStickyAdditionalInfo');
    window.addEventListener('scroll', () => {
      var scroll_position = $(window).scrollTop();
      if(block.hasClass('JsStickyAlready') &&
          scroll_position < $('#JsStickyStop').position().top){
        block.removeClass('JsStickyAlready');
        if(additionalInfo.length > 0) additionalInfo.hide();
      }else if(!block.hasClass('JsStickyAlready') &&
          scroll_position > block.position().top){
        block.addClass('JsStickyAlready');
        if(additionalInfo.length > 0) additionalInfo.show();
      }
    });
  }
});

$(document).on('click', '.JsFilterOrder', function(event) {
  event.preventDefault();

  var prevElement = $('#JsFilterOrderCurrent');
  var prevField = prevElement.data('field'), prevClasses = prevElement.attr('class');
  prevElement.replaceWith(function(){
    return $(
      '<a href="#" class="JsFilterOrder ' + prevClasses + '" data-field="'+prevField+'">' + $(this).html() + '</a>'
    );
  });
  prevElement.addClass();
  prevElement.data('field', prevField);

  var element = $(this);
  var field = element.data('field'), classes = element.attr('class');
  element.replaceWith(function(){
    return $(
      '<span id="JsFilterOrderCurrent" class="' + classes + '" data-field="'+field+'">' + $(this).html() + "</span>"
    ).removeClass('JsFilterOrder');
  });
  $('#JsFilterOrderFormInput').val(field);
  $('.JsFilterButton').click();
});

$(document).on('click', '.JsSwitchAndShowTab', function() {
  event.preventDefault();

  var tabId = $(this).data('tab-id');
  $('#'+tabId).prop("checked", true);
  $('html, body').animate({
      scrollTop: $("#tabs").offset().top
  }, 1000);
});

$(document).on('click', '.JsMapLink', function(event) {
  event.preventDefault();

  var id = 'JsMap' + $(this).data('map-id');
  var element = $('#'+id);

  if(element.is(':visible')){
    element.hide();
    element.html('');
  }else{
    var latitude = element.data('lat'), longitude = element.data('long');
    showYandexMap(id, latitude, longitude);
    element.show();
    var position_element = element.offset().top - ($( window ).height()- element.height()) + 10;
    $([document.documentElement, document.body]).animate({
        scrollTop: position_element
    }, 3000);
  }
});

$(document).on('click', '.JsSelectboxButton', function() {
  // $('.JsSelectboxBody').hide();
  var element = $(this);
  var parent = element.parents('.JsSelectbox');
  if(element.hasClass('JsSelectboxButtonClose')){
    $('.JsSelectboxButtonOpen').each(function(index, button){
      var element = $(this);
      element.parents('.JsSelectbox').find('.JsSelectboxBody').slideToggle('fast');
      element.removeClass('JsSelectboxButtonOpen');
      element.addClass('JsSelectboxButtonClose');
    });
    element.removeClass('JsSelectboxButtonClose');
    element.addClass('JsSelectboxButtonOpen');

    $('body').click(function(event) {
      if (parent.length != 0 && !parent.is(event.target) &&
        parent.has(event.target).length === 0){
        element.click();
      }
    });
  }else{
    element.removeClass('JsSelectboxButtonOpen');
    element.addClass('JsSelectboxButtonClose');
    $('body').off('click');
  }
  parent.find('.JsSelectboxBody').slideToggle('fast');
});

$(document).on('change', '.JsSelectboxCheckbox', function(event) {
  selectBoxCheckbox($(event.target));
});

$(document).on('click', '.JsMapInTabLink', function(event) {
  event.preventDefault();
  $('.JsMapInTabInput').click();
  document.getElementById("JsMapInTab").scrollIntoView({ behavior: 'smooth', block: 'center' });
});

$(document).on('click', '.JsApartComplexHelpClick', function(){
  $('.JsApartComplexHelpContent').hide();

  var apart = $(this).data('apart');
  var element = $('#JsApartComplexHelp' + apart);
  var map = $('#JsApartComplexHelpMap' + apart);
  if(map){
    showYandexMap('JsApartComplexHelpMap' + apart, map.data('lat'), map.data('long'));
  }
  element.show();
});

$(document).on('click', '.JsApartComplexHelpClose', function(){
  $(this).parents('.JsApartComplexHelpContent').hide();
});

$(".dropdown dt").on('click', function () {
  $(".dropdown dd ul").slideToggle('fast');
});

$(".dropdown dd ul li a").on('click', function () {
    $(".dropdown dd ul").hide();
});

$(document).bind('click', function (e) {
  var $clicked = $(e.target);
  if (!$clicked.parents().hasClass("dropdown")) $(".dropdown dd ul").hide();
});

$('.mutliSelect input[type="checkbox"]').on('click', function () {
  // var title = $(this).closest('.mutliSelect').find('input[type="checkbox"]').val(),
  var title = $(this).val() + ",";

  if ($(this).is(':checked')) {
    var html = '<span title="' + title + '">' + title + '</span>';
    $('.multiSel').append(html);
    $(".hida").hide();
  } else {
    $('span[title="' + title + '"]').remove();
    var ret = $(".hida");
    $('.dropdown dt a').append(ret);
  }
});

$(document).on('click', '.JsAccordion', function(){
  var target_id = 'JsAccordion' + $(this).data('target');
  $('#'+target_id).toggle();
});

$(document).on({
  mouseenter: function () {
    $(this).removeClass('JsMessageNew');
  },
}, ".JsMessageNew");

function showYandexMap(id, latitude, longitude){
  ymaps.ready(function() {
    var myMap = new ymaps.Map(id, {
      center: [latitude, longitude],
      zoom: 14
    });
    myMap.geoObjects.add(new ymaps.Placemark([latitude, longitude]));
  });
}

function selectBoxCheckbox(element){
  var parent = element.parents('.JsSelectbox'),
    checkedElement = parent.find('.JsSelectboxTitleChecked'), checkedList = [];

  parent.find('.JsSelectboxCheckbox').each(
    function(index, checkbox){
      var element = $(this);
      if (element.is(':checked')) {
        var nomer = element.val();
        var title = parent.find('.JsSelectboxCheckboxLabel'+nomer).html();
        checkedList.push(
          '<span class="selectbox-head-selectbox-checked-item JsSelectboxTitleChecked'+nomer+'">' +
          title +
          '</span>'
        );
      }
    }
  );

  var defaultElement = parent.find(".JsSelectboxTitleDefault");
  if(checkedList.length == 0){
    defaultElement.show();
    checkedElement.hide();
  }else {
    defaultElement.hide();
    checkedElement.html(checkedList.join(', '));
    checkedElement.show();
  }
}

