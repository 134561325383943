import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  if(document.getElementById('JsScrolling')){
    consumer.subscriptions.create({channel: "MessagesChannel", complex_id: document.getElementById('JsScrolling').getAttribute('data-complex')}, {
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var message = data.message, parent_id = data.parent_id,
          message_id = data.message_id;
        if($('#JsMessage' + message_id).length > 0){
          $('#JsMessage' + message_id).replaceWith(message);
        }else{
          if(parent_id){
            $('#JsMessage' + parent_id + 'Children').prepend(message);
          }else{
            $('#JsScrolling').prepend(message);
          }
        }
      }
    });
  }
})
